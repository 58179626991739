import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-esports',
  templateUrl: './esports.component.html',
  styleUrls: ['./esports.component.css']
})
export class ESportsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = "http://esports.algambo.com"
  }

}
