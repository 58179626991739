import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatIconRegistry, MatIconModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TitleService } from './core/services/title.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { HomeComponent } from './page/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { E404Component } from './errors/e404/e404.component';
import { UnderMaintenanceComponent } from './errors/under-maintenance/under-maintenance.component';
import { ContactComponent } from './page/contact/contact.component';
import { SignupComponent } from './page/signup/signup.component';
import { OptOutComponent } from './page/opt-out/opt-out.component';
import { CareersComponent } from './page/careers/careers.component';
import { TrainingComponent } from './page/training/training.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { TermsComponent } from './page/terms/terms.component';
import { PolicyMenuComponent } from './policy-menu/policy-menu.component';
import { AboutComponent } from './page/about/about.component';
import { ESportsPageComponent } from './page/esports/esports.component';
import { UserAgrementComponent } from './page/user-agrement/user-agrement.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    FooterMenuComponent,
    BackToTopComponent,
    HomeComponent,
    E404Component,
    UnderMaintenanceComponent,
    ContactComponent,
    SignupComponent,
    OptOutComponent,
    CareersComponent,
    TrainingComponent,
    PrivacyComponent,
    TermsComponent,
    PolicyMenuComponent,
    AboutComponent,
    ESportsPageComponent,
    UserAgrementComponent,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    RecaptchaModule.forRoot({
      siteKey: '6Ld6-a0UAAAAACB9HZISHZbdM7NRBHxSL-Xe0BR_',
    }),
  ],
  providers: [TitleService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
