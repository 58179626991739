import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { E404Component } from './errors/e404/e404.component';
import { HomeComponent } from './page/home/home.component';
import { UnderMaintenanceComponent } from './errors/under-maintenance/under-maintenance.component';
import { ContactComponent } from './page/contact/contact.component';
import { SignupComponent } from './page/signup/signup.component';
import { OptOutComponent } from './page/opt-out/opt-out.component';
import { CareersComponent } from './page/careers/careers.component';
import { TrainingComponent } from './page/training/training.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { TermsComponent } from './page/terms/terms.component';
import { AboutComponent } from './page/about/about.component';
import { ESportsPageComponent } from './page/esports/esports.component';
import { UserAgrementComponent } from './page/user-agrement/user-agrement.component';


const ROUTES: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: { title: 'Welcome' } },
  { path: 'home', component: HomeComponent, data: { title: 'Real-Time, Reliable Technology with Global Support' } },
  { path: 'signup', component: SignupComponent, data: { title: 'Create New Account' } },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact Us' } },
  { path: 'blog', component: UnderMaintenanceComponent, data: { title: 'Blog' } },
  { path: 'esports', component: ESportsPageComponent, data: { title: 'eSports' } },
  { path: 'training', component: TrainingComponent, data: { title: 'Training' } },
  { path: 'careers', component: CareersComponent, data: { title: 'Careers' } },
  
  { path: 'about', component: AboutComponent, data: { title: 'About Us' } },
  { path: 'privacy', component: PrivacyComponent, data: { title: 'Privacy Policy' } },
  { path: 'terms', component: TermsComponent, data: { title: 'Terms of Use' } },
  { path: 'user-agreement', component: UserAgrementComponent, data: { title: 'User agreement' } },

  { path: 'opt-out', component: OptOutComponent, data: { title: 'Opt Out' } },
  { path: '404', component: E404Component, data: { title: 'Not Found' } },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
