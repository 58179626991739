import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface UserGeo {
    ip?: string
    country?: string
}

@Injectable({
    providedIn: 'root'
})
export class UserLocationService {

    constructor(private http: HttpClient) { }

    find(): Observable<UserGeo> {
        // return this.duckDuckGoService();
        // return this.ipInfoIoService();
        return this.http.get<UserGeo>(`https://api.country.is`)
    }

    private duckDuckGoService(): Observable<any> {
        return this.http.get<any>(`https://duckduckgo.com/country.json`)
    }

    private ipInfoIoService(): Observable<any> {
        return this.http.get<any>(`https://ipinfo.io/json`)
    }
}
