import { Component, OnInit } from '@angular/core';
import { UserLocationService } from 'src/app/core/services/user-location.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  country: string = 'IN'
  currency: string = 'INR'

  constructor(private locService: UserLocationService) {
    this.locService.find().subscribe((res) => {
      if (res.country) {
        this.country = res.country
        if (this.country != "IN") {
          this.currency = "USD"
        }
      }
    })
  }

  ngOnInit() {}

  featuresList = [
    // {
    //   'i': "",
    //   't': "Offer Access",
    //   'd': "Authorize affiliates' access to offers by setting status - Private, Public or Require Approval. You have full control on matching offers to the right affiliates."
    // },
    // {
    //   'i': "",
    //   't': "Caps Management",
    //   'd': "Manage caps based on conversions, revenue and payouts, by offers or by affiliates. You can also set private caps and assign to specific affiliates."
    // },
    {
      'i': "assets/images/icon/device.svg",
      't': "Advanced Targeting",
      'd': "Find your right users, from geographies to devices to operating systems"
    },
    {
      'i': "assets/images/icon/calendar.svg",
      't': "Advanced Reports",
      'd': "Use default presets or customize your report according to need to solve all business needs."
    },
    {
      'i': "assets/images/icon/stairs.svg",
      't': "Multi Payout Models",
      'd': "Supports different models like CPC, CPM, CPA, CPA%. to know how much you pay, what’s your profit and ROI."
    },
    {
      'i': "assets/images/icon/refresh.svg",
      't': "Real-Time Data",
      'd': "With our real-time reporting system, monitor and make instant decissions."
    },
    {
      'i': "assets/images/icon/cloud.svg",
      't': "Custom SSL Domain",
      'd': "Use your own domain, so your partners reconise you better."
    },
    {
      'i': "assets/images/icon/pen.svg",
      't': "Brand Customization",
      'd': "Use your own brand name, logo, domains etc."
    },
    {
      'i': "assets/images/icon/user.svg",
      't': "Easy To Use",
      'd': "Use our user friendly interface on any device, from desktop to tablet to mobile"
    },
    {
      'i': "assets/images/icon/24-hours.svg",
      't': "24/7 SUPPORT",
      'd': "We are available 24/7 through email, phone, skype or live chat"
    },
  ]

  faqList = [
    {
      'q': 'Can I get an annual plan?',
      'a': `Yes, you can get an annual plan for one, two or three years. Please <a href="/contact">contact us</a> to find out the details.`
    },
    {
      'q': 'Can I upgrade my plan?',
      'a': 'Yes, you can upgrade your plan whenever you wish. Just get in touch with our support to make it happen.'
    },
    {
      'q': 'Will my tracking stop after I reach the monthly limit?',
      'a': 'Of course not! We know how important tracking is to the continuity of a business. After you reach your limit, your events will still be tracked, but you’ll have to pay for the overages, not applicable for the pay-as-go-users.'
    },
    {
      'q': 'What are the overages?',
      'a': 'All events that are not covered by your plan. For example, if you’re a Professional plan user and reach the limit of 5,000,000 events, you will be charged $0.07 per every 1,000 events that exceed the limit.'
    },
    {
      'q': 'What are events?',
      'a': 'An event is any action that is being tracked: a visit, a click, a conversion.'
    },
    {
      'q': 'Can I customize my plan?',
      'a': `Yes, if you’d like to modify parameters of a chosen plan. we’ll be happy to create a custom plan just for you - just <a href="/contact">contact us</a>.`
    },
    {
      'q': 'Can I upgrade or downgrade my plan?',
      'a': `Yes, you can upgrade or downgrade the plan in your account settings or <a href="/contact">contact us</a>. But keep in mind that this action may implicate losing or gaining access to some of the features.</p>`
    },
    {
      'q': 'Can I unsubscribe when I want?',
      'a': 'Yes, uou can unsubscribe anytime. Please note that if it happens in the middle of a billing cycle, the plan will continue to be active until the end of the cycle. We won’t charge you for the next month.'
    },
    {
      'q': 'Can I migrate my campaigns from another tracker?',
      'a': `Yes, we’ll be happy to help you. <a href="/contact">Contact us</a> to discuss the details.`
    },
    {
      'q': 'My needs exceed the Grow plan. What should I do?',
      'a': `we can create a custom plan that will be tailored to your specific requirements. <a href="/contact">Contact</a> our sales team to discuss the details.`
    },
    // {
    //   'q': 'Where can I learn how to use Voluum?',
    //   'a': "On our main page, you can find a tab called “Free Resources”, from where you can access our documentation, video tutorials and step-by-step guides, and the blog. Also, when you log in to Voluum for the first time, you will be guided around the panel. Additionally, once you buy a plan you'll receive onboarding emails and will be offered a group webinar or an individual onboarding, depending on your chosen plan."
    // },
    {
      'q': 'Is my data removed after the retention period?',
      'a': 'We store all data after retention period for certain months before deleting. To gain access to historical data, simply upgrade your plan to reach as far back as you need.'
    },
    // {
    //   'q': 'How can I pay for my plan?',
    //   'a': 'We accept most forms of payment — PayPal, MasterCard, Maestro, Visa, American Express, Diners Club International, Discover Network, and JCB. If you would like to pay with a different payment method, contact us here.'
    // },
    {
      'q': 'Do I need to purchase hosting?',
      'a': "No, all Algambo services are cloud-based SaaS solution, so all the infrastructure management is on us."
    },
  ]
}
