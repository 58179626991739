import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


const API_ENDPOINT = "https://ndsigvckog.execute-api.us-east-1.amazonaws.com/Prod/contactus"

export interface ContactUsData {
  name: string,
  email: string,
  phone?: string,
  subject?: string,
  message: string,
  gRecaptchaResponse: string
}

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }

  sendMessage(data: ContactUsData): Observable<any[]> {
    let phone = '';
    if (data.phone != undefined && data.phone.length > 0) {
      phone = data.phone
    }

    let subject = 'Conatct Us';
    if (data.subject != undefined && data.subject.length > 0) {
      subject = data.subject
    }

    return this.http.post<any>(API_ENDPOINT + "/", {
      'name': data.name,
      'email': data.email,
      'phone': phone,
      'subject': subject,
      'message': data.message,
      'g-recaptcha-response': data.gRecaptchaResponse
    }, {
      headers: {
        'x-api-key': 'na'
      }
    });
  }
}
