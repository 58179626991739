import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

const GA_MEASUREMENT_ID = "UA-139111437-1"
declare var gtag: Function;
declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(private router: Router) { }

    public event(eventName: string, params: {}) {
        gtag('event', eventName, params);
    }

    public init() {
        this.listenForRouteChanges();

        try {
            const script1 = document.createElement('script');
            script1.async = true;
            //   script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + GA_MEASUREMENT_ID;
            document.head.appendChild(script1);

            const script2 = document.createElement('script');
            script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + GA_MEASUREMENT_ID + `', {'send_page_view': false});
      `;
            document.head.appendChild(script2);
        } catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
        }        
    }

    private listenForRouteChanges() {        
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {     
                gtag('config', GA_MEASUREMENT_ID, {
                    'page_path': event.urlAfterRedirects,
                    campaign: {
                        medium: 'web',
                        source: window.location.hostname,
                        name: '',
                        content: ''
                    }
                });
                // console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
                // console.log('Property ID', environment.googleAnalyticsKey);
            }
        });
    }
}
