import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Route, Router, NavigationEnd } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ContactUsService, ContactUsData } from 'src/app/contact-us.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  showForm: boolean = true;
  disableSendbutton: boolean = false;
  sendButtonText: string = "Send Message";
  responseMessage: string;
  errorMessage: string;

  // @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  constructor(private fb: FormBuilder, private contactService: ContactUsService, private router: Router) {
    // override the route reuse strategy
    // this.router.routeReuseStrategy.shouldReuseRoute = function(){
    //    return false;
    // }

    //   this.addRecaptchaScript()

    //   this.router.events.subscribe((evt) => {
    //      if (evt instanceof NavigationEnd) {
    //         // trick the Router into believing it's last link wasn't previously loaded
    //         this.router.navigated = false;
    //         // if you need to scroll back to top, here is the right place
    //         window.scrollTo(0, 0);
    //      }
    //  });

  }

  ngOnInit() {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.addRecaptchaScript()

    this.form = this.fb.group({
      "name": ['', Validators.required],
      "email": ['', [Validators.required, Validators.email]],
      "subject": ['', Validators.required],
      "message": ['', Validators.required],
      "gRecaptchaResponse": [false, Validators.required],
    });
  }

  // gRecaptchaResponse: boolean

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  // renderReCaptch() {
  //   window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
  //     'sitekey' : '6Ld6-a0UAAAAACB9HZISHZbdM7NRBHxSL-Xe0BR_',
  //     'callback': (response) => {
  //         console.log(response);
  //     }
  //   });
  // }

  // addRecaptchaScript() {

  //   window['grecaptchaCallback'] = () => {
  //     this.renderReCaptch();
  //   }

  //   (function (d, s, id, obj) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) { return; }
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'recaptcha-jssdk', this));
  // }


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(recaptcha: any) {
    let data = { ...this.form.value }
    if (data['gRecaptchaResponse']) {
      data['gRecaptchaResponse'] = recaptcha.recaptchaAPI.getResponse()
    }
    console.log(data)

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    // return;
    
    this.disableSendbutton = true
    this.sendButtonText = "Please wait..."

    let payload: ContactUsData = {
      name: data['name'],
      email: data['email'].toLowerCase(),
      // phone: this.form.value['phone'],
      subject: data['subject'] + " [website]",
      message: data['message'],
      gRecaptchaResponse: data['gRecaptchaResponse']
    }

    this.contactService.sendMessage(payload).subscribe(
      resp => {
        console.log(resp);
        this.showForm = false
        this.responseMessage = "Thanks!  We'll contact you soon."
      },
      err => {
        console.log(err);
        this.disableSendbutton = false
        this.sendButtonText = "Send Message"
        this.errorMessage = "Opps!  Unable to send message."
      }
    )
  }

  onReset() {
    this.form.reset()[0];
  }
}
