import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ViewContainerRef, ViewRef, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ContactUsService, ContactUsData } from 'src/app/contact-us.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  form: FormGroup;
  showForm: boolean = true;
  disableSendbutton: boolean = false;
  sendButtonText: string = "Get Started";
  responseMessage: string;
  errorMessage: string;

  // @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  // childViewRef: ViewRef;

  constructor(private fb: FormBuilder, private contactService: ContactUsService, private router: Router) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      "fname": ['', Validators.required],
      "lname": ['', Validators.required],
      "company": ['', Validators.required],
      "email": ['', [Validators.required, Validators.email]],
      "phone": ['', Validators.required],
      "gRecaptchaResponse": [false, Validators.required],
      "accept": [false, Validators.requiredTrue],
    });
  }

  // myRecaptcha: boolean

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(recaptcha: any) {
    let data = { ...this.form.value }
    if (data['gRecaptchaResponse']) {
      data['gRecaptchaResponse'] = recaptcha.recaptchaAPI.getResponse()
    }
    console.log(data)

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    // return;

    this.disableSendbutton = true
    this.sendButtonText = "Please wait..."

    let payload: ContactUsData = {
      name: data['fname'] + ' ' + data['lname'],
      email: data['email'].toLowerCase(),
      phone: data['phone'],
      subject: "Get Started for Free [website]",
      message: 'Company: ' + data['company'] + "\n\t" + "Accept: " + data['accept'],
      gRecaptchaResponse: data['gRecaptchaResponse']
    }

    this.contactService.sendMessage(payload).subscribe(
      resp => {
        console.log(resp);
        this.showForm = false
        this.responseMessage = "Thank you for choosing us! We'll contact you soon."
      },
      err => {
        console.log(err);
        this.disableSendbutton = false
        this.sendButtonText = "Get Started"
        this.errorMessage = "Opps!  Unable to send message."
      }
    )
  }

  onReset() {
    this.form.reset()[0];
  }

}
