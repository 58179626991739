import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Menu
    $('.navbar-toggle').on('click', function (event) {
      $(this).toggleClass('open');
      $('#navigation').slideToggle(400);
    });

    $('.navigation-menu>li').slice(-1).addClass('last-elements');

    $('.menu-arrow,.submenu-arrow').on('click', function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
      }
    });

    $(".navigation-menu a").each(function () {
      if (this.href == window.location.href) {
        $(this).parent().addClass("active");
        $(this).parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("active");
      }
    });

    // Clickable Menu
    $(".has-submenu a").click(function () {
      if (window.innerWidth < 992) {
        if ($(this).parent().hasClass('open')) {
          $(this).siblings('.submenu').removeClass('open');
          $(this).parent().removeClass('open');
        } else {
          $(this).siblings('.submenu').addClass('open');
          $(this).parent().addClass('open');
        }
      }
    });

    $('.mouse-down').on('click', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top - 0
      }, 1500, 'easeInOutExpo');
      event.preventDefault();
    });
  }

}
