import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opt-out',
  templateUrl: './opt-out.component.html',
  styleUrls: ['./opt-out.component.css']
})
export class OptOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    (window as any).optOut();
  }

}
